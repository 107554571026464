// ... existing imports ...
import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { createDozerCoin } from '../../../apis/atlantis.api'; // Import the function from dozer.api.js

const CreateDozerCoinsModal = ({ show, onHide, token }) => {
  const [walletAddress, setWalletAddress] = useState('');
  const [coinAmount, setCoinAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [failedWallets, setFailedWallets] = useState([]); // Change to an array for multiple failed wallets

  const handleReward = async () => {
    setLoading(true);
    setSuccess(false);

    try {
      const wallets = JSON.parse(walletAddress); // Parse the JSON input
      if (!Array.isArray(wallets)) {
        setError('Input must be a JSON array'); // Set error for non-array input
        return; // Stop execution if input is not a JSON array
      }
      for (const wallet of wallets) {
        const response = await createDozerCoin(wallet, coinAmount, token); // Call the function for each wallet
        if (!response?.id) {
          setFailedWallets(wallets.slice(wallets.indexOf(wallet))); // Store all subsequent wallets
          break; // Stop on the first failure
        }
      }
      setSuccess(true); // If all succeed, set success
    } catch (e) {
      console.log(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setWalletAddress(''); // Reset wallet address
    setCoinAmount(0); // Reset coin amount
    setFailedWallets([]); // Clear failed wallets
    setSuccess(false); // Reset success state
    onHide(); // Call the onHide function
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Reward Dozer Coin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success && (
          <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
            Dozer Coin successfully dropped!
          </Alert>
        )}
        <Form>
          <Form.Group controlId="walletAddresses">
            <Form.Label>Wallet Addresses (comma-separated)</Form.Label>
            <Form.Control
              as="textarea"
              placeholder='Paste wallet IDs as a JSON array, e.g. ["8UNuw6Wv8F47ymuCdZxzN29fK5ETafoyz2ih9YYAozVw", ...]'
              value={walletAddress}
              style={{
                height: '300px',
                overflowY: 'auto', // Enable vertical scrolling
                overflowX: 'hidden', // Prevent horizontal scrolling
                whiteSpace: 'pre-wrap', // Ensure text wraps within the textarea
                resize: 'none',
                borderColor: failedWallets.length > 0 ? 'red' : undefined,
              }}
              onChange={e => setWalletAddress(e.target.value)}
              required
            />
            {failedWallets.length > 0 && (
              <div style={{ marginTop: '10px', color: 'red' }}>
                <strong>Failed Wallets:</strong>
                <ul style={{ listStyleType: 'none', padding: '0' }}>
                  {failedWallets.map((wallet, index) => (
                    <li key={index} style={{ backgroundColor: 'yellow' }}>
                      {wallet}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Form.Group>
          {error && (
            <div style={{ marginTop: '10px', color: 'red' }}>
              <strong>Error:</strong> {error.message || String(error)}
            </div>
          )}
          <Form.Group controlId="coinAmount" className="mt-3">
            <Form.Label>Coin Amount</Form.Label>
            <Form.Control
              type="number"
              min="1"
              step="1"
              placeholder="Enter Coin Amount"
              value={coinAmount}
              onChange={e => {
                setCoinAmount(e.target.value);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleReward} disabled={loading || coinAmount <= 0}>
          {loading ? 'Loading...' : 'Reward'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateDozerCoinsModal;
